import React from 'react';
import LoaderGif from '../../assets/loader.gif'

const FullLoader = () => {
    return ( 
        <div style={{
            "width" : "100vw", 
            "height" : "100vh", 
            "display" : "flex", 
            "flex-direction" : "column", 
            "justify-content" : "center"
        }}>
            <img src={LoaderGif} style={{
                "width": "20%", 
                "height" : "auto", 
                "align-self" : "center",
            }} />
        </div>
    );
}
 
export default FullLoader;