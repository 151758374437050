import React from 'react';

const ProjectItem = ({project}) => {        
    return (         
        <React.Fragment>
            <div className="grid">
                <div className="col-1">
                    <img src={project.image} /> 
                </div>                
                <div className="col-11 m-left-20">
                    <h4 className="no-margin">{renderProjectName(project)}</h4>
                    <div className="project-description text-brown m-top-10">
                        {project.description}
                    </div>                    
                </div>
            </div>

            <hr className="m-top-20 m-bottom-20"/>
        </React.Fragment>        
    );
}

const renderProjectName = (project) => {
    if(project.link === ""){
        return project.name
    }
    
    return <a href={project.link} target="blank">{project.name}</a>
}
 
export default ProjectItem;