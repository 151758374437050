import React from 'react';
import aad from '../../assets/aad.jpg';
import inc from '../../assets/inc.jpg';
import am from '../../assets/am.jpg';
import amdp1 from '../../assets/amdp1.jpg';
import amdp2 from '../../assets/amdp2.jpg';
import bootcamp from '../../assets/bootcamp.jpg';
import androidpemula from '../../assets/androidpemula.jpg';
import kotlinpemula from '../../assets/kotlinpemula.jpg';
import solid from '../../assets/solid.jpg';
import pythonpemula from '../../assets/pythonpemula.jpg';
import made from '../../assets/made.jpg';
import kade from '../../assets/kade.jpg';
import jetpack from '../../assets/jetpack.jpg';
import webdasar from '../../assets/webdasar.jpg';
import dsccoreteam from '../../assets/dsc_core_team.png';
import frontend from '../../assets/frontend.jpg';
import hackerrank_problemsolving from '../../assets/hackerrank_problemsolving.png';
import hackerrank_c_basic from '../../assets/hackerrank_c_basic.png';
import hackerrank_c_intermediate from '../../assets/hackerrank_c_intermediate.png';
import praetorian from '../../assets/praetorian.png';
import bnccxgojek from '../../assets/bncc_gojek.jpeg';
import pwa from '../../assets/pwa.png';
import dscugm_workshop from '../../assets/dscugm_workshop.jpeg';
import dasar_aws_cloud from '../../assets/dasar_aws_cloud.png';
import swift from '../../assets/swift.png';
import ios_pemula from '../../assets/ios_pemula.jpg';
import tokopedia_workshop_performance from '../../assets/tokopedia_workshop_performance_certificate.png'
import bangkit_2022 from '../../assets/bangkit2022.jpeg'
import hackathon from '../../assets/hackathon.png'
import bangkit_team_incubation_mentor_2022 from '../../assets/bangkit_team_incubation_mentor.jpeg'
import bangkit2023_batch2 from '../../assets/bangkit2023_batch2.png'
import bangkit2024_batch1 from '../../assets/bangkit2024_batch1.jpg'
import CertificationAchievementItem from './certificationAchievementItem';

let certificateList = [
    bangkit2024_batch1,
    bangkit2023_batch2,
    bangkit_team_incubation_mentor_2022,
    bangkit_2022,
    aad,
    tokopedia_workshop_performance,
    hackathon,
    dsccoreteam,
    dscugm_workshop,
    bnccxgojek,
    praetorian,
    inc,
    am,
    amdp1,
    amdp2,
    bootcamp,
    androidpemula,
    kotlinpemula,
    pythonpemula,
    solid,
    made,
    kade,
    jetpack,
    frontend,
    webdasar,
    pwa,
    dasar_aws_cloud,
    swift,
    ios_pemula,
    hackerrank_c_basic,
    hackerrank_c_intermediate,
    hackerrank_problemsolving,
]

const CertificateAchievement = () => {
    return (  
        <section id="section-certificate-achievement">
            <div className="certificate-achievement section-white">
                <h1><i className="fa fa-trophy m-right-10"></i>Certificate & Achievement</h1>
                <div className="m-top-50 m-left-20">
                    {generateCertificateAchievementItem()}
                </div>
            </div>
        </section>    
    );
}

const generateCertificateAchievementItem = () => {
    let certificatePairList = []
    for(let i=0; i<certificateList.length; i+=2) {
        let data1 = certificateList[i]
        let data2 = null
        if(i+1 < certificateList.length) {
            data2 = certificateList[i+1]
        }

        if(data2 != null) {
            certificatePairList.push([data1, data2])
        }
        else {
            certificatePairList.push([data1])
        }        
    }


    return certificatePairList.map(certificatePair => {
        if(certificatePair.length == 2) {
            return (
                <div className="grid m-top-10">
                    <div className="col-5"> 
                        <img src={certificatePair[0]} className="bg-shadow" />                                                                                                     
                    </div>
                    <div className="col-1"></div>
                    <div className="col-5">         
                        <img src={certificatePair[1]} className="bg-shadow" />                                                                                                     
                    </div>                    
                </div> 
            )
        }
        else {
            return (
                <div className="grid m-top-10">
                    <div className="col-5"> 
                        <img src={certificatePair[0]} className="bg-shadow" />                                                                                                     
                    </div>
                    <div className="col-1"></div>
                    <div className="col-5">         
                        
                    </div>                    
                </div> 
            )
        }
    })
}

export default CertificateAchievement;