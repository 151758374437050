import React from 'react';

const Footer = () => {
    return ( 
        <div className="footer section-black">
            <div className="grid">
                <div className="col-12 text-center">
                    © Jonathan Darwin 2020  
                </div>
            </div>        
        </div>
    );
}
 
export default Footer;