import React, { Component } from 'react';


class Navbar extends Component {
    state = {  
        navClass : "nav"
    }

    render() { 
        return (  
            <div className={this.state.navClass}>
                <a className="nav-bar" onClick={this.onNavBarClick}><i className="fa fa-bars m-right-20"></i>Menu</a>
                <a className="nav-item" href="#section-home">Home</a>
                <a className="nav-item" href="#section-profile">Profile</a>
                <a className="nav-item" href="#section-working-experience">Working Experience</a>
                <a className="nav-item" href="#section-organization">Organization</a>
                <a className="nav-item" href="#section-project">Project</a>
                <a className="nav-item" href="#section-skill">Skill</a>
                <a className="nav-item" href="#section-certificate-achievement">Certificate & Achievement</a>
                <a className="nav-item" href="#section-featured-event">Featured Event</a>
                <a className="nav-item" href="#section-contact">Contact</a>                
            </div>
        );
    }

    onNavBarClick = () => {
        let navClass = this.state.navClass
        if(this.state.navClass === "nav"){
            navClass += " responsive"
        }     
        else{
            navClass = "nav"
        }   
        this.setState({
            navClass : navClass
        })
    }
}
 
export default Navbar;