import React from 'react';
import WorkingExperienceItem from './workingExperienceItem';

const WorkingExperience = () => {          
    return (  
        <section id="section-working-experience">
            <div class="working-experience section-white">
            <h1><i class="fa fa-suitcase m-right-10"></i>Working Experience</h1>                
                <div className="p-left-20 p-top-50">
                    {generateWorkingExperienceItem()}
                </div>                                                                
            </div>
        </section>
    );
}

const generateWorkingExperienceItem = () => {
    return getData().map(company => {
        return <WorkingExperienceItem company={company} />
    })
}

const getData = () => {
    return [
        // {
        //     "companyName" : "Kiny Labs",
        //     "startDate" : "2023-03-25",
        //     "endDate" : "",
        //     "image" : require('../../assets/kinylabs.png'),
        //     "isRounded" : false,
        //     "position" : [
        //         {
        //             "positionName" : "Co-Founder",
        //             "startDate" : "2023-03-25",
        //             "endDate" : "",
        //             "description": []
        //         },
        //     ]
        // },
        {
            "companyName" : "Tokopedia",
            "startDate" : "2021-06-21",
            "endDate" : "2024-08-16",
            "image" : require('../../assets/tokopedia.png'),
            "isRounded" : false,
            "position" : [
                {
                    "positionName" : "Senior Software Engineer - Android",
                    "startDate" : "2023-07-1",
                    "endDate" : "2024-08-16",
                    "description": [
                        "Handling content creation features (live streaming, feed post, shorts video, and stories).",
                        "Improving content upload by implementing a queue system that allows users to upload content in parallel. • Initiating logging mechanism in content upload system to make bug tracing easier.",
                        "Enhancing User Profile page experience by supporting the page layout on multiple screen size.",
                        "Researching, planning, and initiating Jetpack Compose in Tokopedia to increase developers productivity.",
                        "Improving Screenshot Testing by utilizing Showkase to generate automated test cases.",
                        "Guiding and helping junior members in developing features and improvements."
                    ]
                },
                {
                    "positionName" : "Software Engineer - Android",
                    "startDate" : "2021-06-21",
                    "endDate" : "2023-06-01",
                    "description" : [
                        "Handling content creation for both livestream (Tokopedia Play) & feed.",
                        "Initiating SSE implementation in Tokopedia android tech as another option for real time update mechanism.",
                        "Implementing chromecast feature on Tokopedia Play to increase user watching experience.",
                        "Simplifying the live creation process to make it easier for creators to start a live stream.",
                        "Enhancing users interaction in the live room by creating an animation when the user clicks like.",
                        "Refactoring and adopting the latest recommended android technology on the User Profile Page.",
                        "Increasing unit test coverage on the User Profile module from 0% to 90%.",
                        "Improving multiple media upload processes by making it asynchronous.",
                        "Decreasing APK size for around 1mb by grouping content creation modules as Dynamic Features & removing unused resources.",
                        "Optimizing android hiring project scoring by creating an automation based on unit & instrumentation tests.",
                        "Becoming Documentation Champion : leading & monitoring squad documentation."
                    ]
                }
            ]
        },
        {
            "companyName" : "PARKEE",
            "startDate" : "2021-03-01",
            "endDate" : "2021-06-11",
            "image" : require('../../assets/parkee.png'),
            "isRounded" : true,
            "position" : [
                {
                    "positionName" : "Android Engineer",
                    "startDate" : "2021-03-01",
                    "endDate" : "2021-06-11",
                    "description" : [
                        "Working on Parkee Customer's App",
                        "Creating several features like On-Street, SSO, and Redesign Quickbook (Existing Feature)"
                    ]
                }
            ]
        },
        {
            "companyName" : "Bina Nusantara - IT Division",
            "startDate" : "2018-03-01",
            "endDate" : "2021-02-01",
            "image" : require('../../assets/binus.png'),
            "isRounded" : true,
            "position" : [
                {
                    "positionName" : "Programmer - Android Developer",
                    "startDate" : "2020-10-01",
                    "endDate" : "2021-02-01",
                    "description" : [
                        "Develop Binus Mobile for Student, Lecturer, and Parent (Android).",
                        "Take responsibility for mobile android development & API used by mobile application."
                    ]
                },
                {
                    "positionName" : "Junior Programmer - Android Developer",
                    "startDate" : "2019-03-01",
                    "endDate" : "2020-09-01",
                    "description" : [
                        "Develop Binus Mobile for Student & Binus Mobile for Lecturer (Android).",
                        "Take responsibility for mobile android development & API used by mobile application."
                    ]
                },
                {
                    "positionName" : "Associate Member",
                    "startDate" : "2018-03-01",
                    "endDate" : "2019-02-01",
                    "description" : [                        
                        "Develop Keuskupan Agung Jakarta Pelayanan Website.",
                        "Do Enhancement for ASAK (Ayo Sekolah Ayo Kuliah) Website",
                        "Do Enhancement for HRMS (Human Resources Management System) Website - Internal Application"
                    ]
                }
            ]
        },
        {
            "companyName" : "by.U",
            "startDate" : "2020-02-01",
            "endDate" : "2020-04-01",
            "image" : require('../../assets/byu.png'),
            "isRounded" : false,
            "position" : [
                {
                    "positionName" : "Developer Freelance",
                    "startDate" : "2020-02-01",
                    "endDate" : "2020-04-01",                 
                    "description" : [
                        "Develop Salvo Tools for Android - Application for Agent.",
                        "Develop Salvo Web Dashboard - Internal Application."
                    ]
                }
            ]
        }
    ]
}
 
export default WorkingExperience;