import React from 'react';
import default_event from '../../assets/default_event.jpg';
import bangkit_blog_banner from '../../assets/bangkit_blog_banner.jpeg';

const FeaturedEvent = () => {
    return(
        <section id="section-featured-event">
            <div class="featured-event section-white">
            <h1><i class="fa fa-calendar m-right-10"></i>Featured Event</h1>                
                <div className="p-left-20 p-top-50">
                    <div className="grid">
                        <div className="col-5 text-center">
                            <img width="560" height="315" src={bangkit_blog_banner} className="bg-shadow" />
                            <br />
                            <a href="https://blog.bangkit.academy/2024/06/a-personal-reflection-on-what-makes.html" target="blank">Bangkit Blog</a>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 text-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/_7GIyuOljOU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="bg-shadow" allowfullscreen></iframe>
                            <br />
                            <a href="https://www.youtube.com/watch?v=_7GIyuOljOU" target="blank">Dicoding Developer Coaching 150: ML Kit on Android</a>
                        </div>
                    </div>
                    <div className="grid m-top-50">
                        <div className="col-5 text-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/cHUv925g4cc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="bg-shadow" allowfullscreen></iframe>
                            <br />
                            <a href="https://www.youtube.com/watch?v=cHUv925g4cc" target="blank">KotakTalks 09 : Cara Menjadi Seorang Mobile Developer</a>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 text-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/7L1uyLh4ohs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="bg-shadow" allowfullscreen></iframe>
                            <br />
                            <a href="https://www.youtube.com/watch?v=7L1uyLh4ohs" target="blank">DSC UGM's Workshop : Build a Cross Platform App with Flutter</a>
                        </div>
                    </div>
                    <div className="grid m-top-50">
                        <div className="col-5 text-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/RkPUWh9pYTk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="bg-shadow" allowfullscreen></iframe>
                            <br />
                            <a href="https://www.youtube.com/watch?v=RkPUWh9pYTk" target="blank">DSC Binus Kemanggisan Event : Building Android Todo App with Room</a>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 text-center">
                            <img width="560" height="315" src={default_event} className="bg-shadow" />
                            <br />
                            <a href="https://www.instagram.com/p/CPqM9_3hVKA/" target="blank">HackGov UGM 2021 : Mobile Mentor</a>
                        </div>
                    </div>
                    <div className="grid m-top-50">
                        <div className="col-5 text-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/1cDcuwgSjH4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="bg-shadow" allowfullscreen></iframe>
                            <br />
                            <a href="https://www.youtube.com/watch?v=RkPUWh9pYTk" target="blank">KSM Android UPNVJ: Membuat Aplikasi News dengan Jetpack Compose</a>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 text-center">
                            <img width="560" height="315" src={require('../../assets/tokopedia_workshop_performance.jpeg')} className="bg-shadow img-crop-top" />
                            <br />
                            Tokopedia Academy Workshop : Android Apps Performance Best Practice &amp; Optimization
                        </div>
                    </div>
                    <div className="grid m-top-50">
                        <div className="col-5 text-center">
                            <img width="560" height="315" src={default_event} className="bg-shadow" />
                            <br />
                            Binus University - HIMTI Workshop : Introduction to Flutter
                        </div>
                        <div className="col-1"></div>
                        <div className="col-5 text-center">
                            <img width="560" height="315" src={default_event} className="bg-shadow" />
                            <br />
                            GoTo Devcamp 2023 - Frontend Track
                        </div>
                    </div>
                </div>                                                                
            </div>
        </section>
    );
}

export default FeaturedEvent;