import React from 'react';
import ProjectItem from './projectItem';

const Project = () => {
    return (  
        <section id="section-project">
            <div className="project section-white">
                <h1><i className="fa fa-laptop m-right-10"></i>Project</h1>
                <div className="m-left-20 p-top-50">                    
                    {generateProjectItem()}                    
                </div>            
            </div>
        </section>
    );
}

const generateProjectItem = () => {
    return getData().map(project => {                
        return (
            <ProjectItem key={project.name} project={project} />
        );
    })
}

const getData = () => {
    return [
        {            
            "image" : require('../../assets/binus_mobile_student.jpg'),
            "name" : "Binus Mobile for Student",
            "description" : "BINUS Mobile for Student is simply an all in one mobile app that will enhance student learning experience in Bina Nusantara by providing easier information access. By utilizing BINUS University Mobile for Student, Binusian will be able to access relevant information for their activity in Bina Nusantara. While providing student activity information including Undergraduate and Graduate Program on BINUS Online Learning, BINUS Kemanggisan, Alam Sutera, Bekasi, Bandung and Malang.",
            "link" : "https://play.google.com/store/apps/details?id=binus.itdivision.mobilestudent&hl=in"     
        },
        {            
            "image" : require('../../assets/binus_mobile_lecturer.jpg'),
            "name" : "Binus Mobile for Lecturer",
            "description" : "BINUS Mobile for Lecturer is simply an all in one mobile app that will enhance lecture experience in Bina Nusantara by providing easier information access. By utilizing BINUS University Mobile for Lecturer, Binusian will be able to access relevant information for their activity in Bina Nusantara. While providing Lecture activity information including Undergraduate and Graduate Program on BINUS Online Learning, BINUS Kemanggisan, Alam Sutera, Bekasi, Bandung and Malang.",
            "link" : "https://play.google.com/store/apps/details?id=binus.itdivision.mobilelecturer&hl=in"     
        },
        {            
            "image" : require('../../assets/kaj.png'),
            "name" : "Keuskupan Agung Jakarta Pelayanan Website - Internal Application",
            "description" : "Website application for internal administration purpose. Used by church administrator in Jakarta.",
            "link" : ""     
        },
        {            
            "image" : require('../../assets/asak.png'),
            "name" : "Ayo Sekolah Ayo Kuliah - Internal Application",
            "description" : "ASAK is a social movement initiate by Keuskupan Agung Jakarta to helped children from poor family to have a proper education. This website is used for internal administrator purpose.",
            "link" : ""     
        },
        {            
            "image" : require('../../assets/itdivision.jpg'),
            "name" : "Human Resources Management System - Internal Application",
            "description" : "HRMS is a website for Associate Member management, including input working schedule, leave request, schedule substitution, etc. This website is used by Associate Member in IT Division and IT Division administrator.",
            "link" : ""     
        },
        {            
            "image" : require('../../assets/salvotools.jpg'),
            "name" : "Salvo Tools Mobile for Agent",
            "description" : "A mobile application used by by.U agent for recording and mapping transaction.",
            "link" : ""     
        },
        {            
            "image" : require('../../assets/lowkerjamedan.png'),
            "name" : "Lowkerja Medan - Website & Android Apps",
            "description" : "Lowkerja Medan is a job vacancy platform around Medan. Already live & available on Google Play Store.",
            "link" : "https://play.google.com/store/apps/details?id=com.lowkerjamedan.lowkerjamedan"     
        },
        {            
            "image" : require('../../assets/android.svg'),
            "name" : "NotNote",
            "description" : "Mobile apps to classify notes category (food, secret, to-do-list) with TF-IDF and Logistic Regression (Natural Language Processing).",
            "link" : "https://github.com/jonathandarwin/NotNote"     
        },
        {            
            "image" : require('../../assets/designme.png'),
            "name" : "DesignMe",
            "description" : "A marketplace application for artist and user who searching for a fresh design. The user can post some requested design and the artists can submit their own design.",
            "link" : ""
        },
        {            
            "image" : require('../../assets/kepo.jpg'),
            "name" : "Kepo",
            "description" : "An application where you can store your to-do list and see others to-do list. Build on Android Jetpack and using android best practices. For IT Division Bootcamp AMJS Final Project 2021.",
            "link" : ""
        },
        {            
            "image" : require('../../assets/ceker.png'),
            "name" : "Ceker",
            "description" : "Android application for inspecting networking (endpoint, parameter, request, response) using content provider. For IT Division Binus internal purpose.",
            "link" : ""
        }
    ]
}
 
export default Project;