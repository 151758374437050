import React from 'react';
import OrganizationItem from './organizationItem';

const Organization = () => {
    return (  
        <section id="section-organization">
            <div class="organization section-white">
            <h1><i class="fa fa-building-o m-right-10"></i>Organization</h1>                
                <div className="p-left-20 p-top-50">
                    {generateOrganizationItem()}
                </div>                                                                
            </div>
        </section>
    );
}

const generateOrganizationItem = () => {
    return getData().map(organization => {
        return <OrganizationItem organization={organization} />
    })
}

const getData = () => {
    return [
        {
            "organizationName" : "Bangkit",
            "description" : "Independent study program led by Google, GoTo, Traveloka for undergraduate students.",
            "startDate" : "2022-02-01",
            "endDate" : "",
            "image" : require('../../assets/bangkit.jpeg'),
            "position" : [
                {
                    "positionName" : "Team Incubation Mentor",
                    "startDate" : "2022-07-01",
                    "endDate" : "",
                    "description" : [
                        "Mentoring one of the Top 15 Bangkit 2022 teams."
                    ]
                },
                {
                    "positionName" : "Judge",
                    "startDate" : "2022-06-01",
                    "endDate" : "2022-06-01",
                    "description" : [
                        "Part of Bangkit 2022 Product Capstone Judge."
                    ]
                },
                {
                    "positionName" : "Mentor",
                    "startDate" : "2022-05-01",
                    "endDate" : "2022-06-01",
                    "description" : [
                        "Mentoring 2 teams on their capstone projects."
                    ]
                },
                {
                    "positionName" : "Instructor",
                    "startDate" : "2022-02-01",
                    "endDate" : "2022-06-01",
                    "description" : [
                        "Leading ILT session with topic : Kotlin Programming.",
                        "Leading ILT session with topic : Android Fundamentals - Fundamental & Navigation.",
                        "Leading ILT session with topic : Android Fundamentals - Networking, Architecture Component, & Local Data Persistent.",
                        "Leading ILT session with topic : Preparation for AAD Certification.",
                    ]
                },
            ]
        },
        {
            "organizationName" : "Google Developer Student Club",
            "description" : "Community groups for college and university students interested in Google developer technologies.",
            "startDate" : "2019-10-01",
            "endDate" : "2021-07-01",
            "image" : require('../../assets/dsc.png'),
            "position" : [
                {
                    "positionName" : "Lead",
                    "startDate" : "2020-08-01",
                    "endDate" : "2021-07-01",
                    "description" : []
                },
                {
                    "positionName" : "Core Team - Android Trainer",
                    "startDate" : "2019-10-01",
                    "endDate" : "2020-04-01",
                    "description" : []
                }
            ]
        },
        {
            "organizationName" : "Bina Nusantara Computing Club",
            "startDate" : "2019-10-01",
            "endDate" : "2020-06-01",
            "image" : require('../../assets/bncc.png'),
            "position" : [
                {
                    "positionName" : "Praetorian - Android Trainer",
                    "startDate" : "2019-10-01",
                    "endDate" : "2020-06-01",
                    "description" : []
                }
            ]
        },
    ]    
}
 
export default Organization;