import React from 'react';
import SkillItem from './skillItem';

const Skill = () => {
    return (  
        <section id="section-skill">
        <div class="skill section-white">
            <h1><i class="fa fa-cogs m-right-10"></i>Skill</h1>
                <div class="p-left-20 p-right-20">
                    {generateSkillItem()}
                </div>
            </div>
        </section>   
    );
}

const generateSkillItem = () => {
    return getData().map(skill => {
        return <SkillItem skill={skill} />
    })
}

const getData = () => {
    return [
        {
            "techName" : "Mobile Development",
            "techList" : [
                "Kotlin",
                "Java",
                "Flutter",
            ]
        },
        {
            "techName" : "Frontend",
            "techList" : [
                "HTML",
                "CSS",
                "Javascript",
                "React"
            ]
        },
        {
            "techName" : "Backend",
            "techList" : [
                "ASP.NET MVC",
                "Node.js",
                "Laravel",
                "Python"
            ]
        },
        {
            "techName" : "Database",
            "techList" : [
                "Microsoft SQL Server",
                "MySQL",
                "PostgreSQL",
                "Firebase"
            ]
        },
        {
            "techName" : "Artificial Intelligence",
            "techList" : [
                "Python"
            ]
        },
    ]
}
 
export default Skill;