import React from 'react';

const ScrollTop = () => {
    return (
        <a className="scrolltop" href="#section-home">
            <i className="fa fa-arrow-up"></i>
        </a>    
    )
}

export default ScrollTop