import React, { useEffect, useState } from 'react';
import './App.css';
import './style.css'
import Navbar from './components/navbar/navbar';
import ScrollTop from './components/navbar/scrolltop';
import Home from './components/home/home';
import Profile from './components/profile/profile';
import Footer from './components/footer/footer';
import Contact from './components/contact/contact';
import CertificateAchievement from './components/certificate-achievement/certificateAchievement';
import Project from './components/project/project';
import Skill from './components/skill/skill';
import WorkingExperience from './components/working-experience/workingExperience';
import Organization from './components/organization/organization';
import headers from './network/APIConfig';
import FeaturedEvent from './components/featured-event/featuredEvent';
import FullLoader from './components/full-loader/fullLoader';
import {getSheetData, appendLog} from './util/googleSheetHelper';

function insertLog(){
  if(isLogDisabled()) return

  fetch(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {

        return appendLog();
        // return fetch('https://api.jonathandarwin.com/log', {
        //   method : 'POST',
        //   headers : headers,
        //   body : JSON.stringify({
        //     city : data.city,
        //     region : data.region,
        //     countryName : data.country_name,
        //     source : document.referrer,
        //     latitude : `${data.latitude}`,
        //     longitude : `${data.longitude}`
        //   })
        // })
    })  
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => {
      console.log(error)
    })
}

function isLogDisabled() {
  return isDev() || queryNoLog()
}

function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

function queryNoLog() {
  const queryParams = new URLSearchParams(window.location.search)
  const log = queryParams.get("log")

  if(log == '') return false

  return log == 'false'
}

function getValueFromColumn(data, columnName) {
  let col = data.values[0].indexOf(columnName)
  return data.values[1][col]
}


function App() {

  const [isFullyLoaded, setIsFullyLoaded] = useState(false)
  const [tags, setTags] = useState(localStorage.getItem("Tags") == null ? [] : localStorage.getItem("Tags").split(","))
  const [location, setLocation] = useState(localStorage.getItem("Location"));
  const [description, setDescription] = useState(localStorage.getItem("Description"));

  useEffect(() => {
    // insertLog();

    getSheetData("Personal Info")
      .then((data) => {
        let tagsValue = getValueFromColumn(data, "Tags").split(",")
        setTags(tagsValue)
        localStorage.setItem("Tags", tagsValue)

        let locationValue = getValueFromColumn(data, "Location")
        setLocation(locationValue)
        localStorage.setItem("Location", locationValue)

        let descriptionValue = getValueFromColumn(data, "Description")
        setDescription(descriptionValue)
        localStorage.setItem("Description", descriptionValue)
        
        setIsFullyLoaded(true)
      })
      .catch((error) => {
        setIsFullyLoaded(true)
      })
  }, [])

  return (
    <React.Fragment>
      {
        isFullyLoaded ? 
          <>
            <Navbar />
            <ScrollTop />
            <Home tags={tags}/>
            <Profile location={location} description={description}/>
            <WorkingExperience />
            <Organization />
            <Skill />
            <Project />
            <CertificateAchievement />
            <FeaturedEvent />
            <Contact />
            <Footer /> 
          </>
        : <FullLoader /> 
      }
    </React.Fragment>
  );
}

export default App;
