import React, { Component } from 'react';
import headers from '../../network/APIConfig';

class Contact extends Component {
    state = {  
        name : '',
        email: '',
        message: '',
        buttonText : 'Send',
        errorMessage: '',
        displayError : ''
    }

    render() { 

        return (  
            <section id="section-contact">
                <div className="contact section-grey">
                    <h1><i className="fa fa-phone m-right-10"></i>Contact</h1>
                    <div className="grid-block m-top-20 m-left-20 m-right-20">
                        <div className="col-6">
                            <h3 className="no-margin">Please do contact me if you have any interest</h3>                            
                            <div className="grid m-top-20">
                                <div className="col-4">Email (Personal)</div>
                                <div className="col-6 text-brown">jonathandarwin.jd@gmail.com</div>
                            </div>                
                            <div className="grid m-top-10">
                                <div className="col-4">Line</div>
                                <div className="col-6 text-brown">jnthndrwn</div>
                            </div>
                            <div className="grid m-top-10">
                                <div className="col-4">Instagram</div>
                                <div className="col-6 text-brown">jonathandarwinn</div>
                            </div>
                        </div>
                        <div id="send-email" className="col-6 vertical">
                        <h3 className="no-margin m-bottom-20"><i className="fa fa-envelope m-right-10"></i> Send Me an Email </h3>                                                        
                            <input type="text" placeholder="Name" onChange={this.handleInputName}/>                                
                            <input type="email" placeholder="Email" onChange={this.handleInputEmail}/>                                
                            <textarea rows="5" onChange={this.handleInputMessage} placeholder="Message"></textarea>
                            <div id="error-message" className={this.state.displayError}>{this.state.errorMessage}</div>
                            <button className="btn-send m-top-10" onClick={this.handleSendEmail}>{this.state.buttonText}</button>                            
                        </div>
                    </div>
                </div>
            </section>  
        );
    }
    
    handleInputName = (e) => {
        this.setState({
            name: e.target.value
        })
    }    

    handleInputEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }    

    handleInputMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }    

    validateEmail = () => {
        let { name, email, message } = this.state
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(name.trim() === ''){
            return 'Please input your name'
        }
        if(email.trim() === ''){
            return 'Please input your email'
        }
        if(!re.test(email.trim().toLowerCase())){            
            return 'Your email is invalid'
        }
        if(message.trim() === ''){
            return 'Please input your message'
        }
        return ''
    }

    handleSendEmail = () => {
        let errorMessage = this.validateEmail()
        let isLoading = this.state.buttonText === 'Loading'

        if(!isLoading){
            if(errorMessage === ''){
                this.setState({
                    errorMessage : errorMessage,
                    displayError : '',
                    buttonText : 'Loading'
                })
    
                this.sendEmail({
                    from : this.state.email,
                    to: `jonathandarwin.jd@gmail.com`,
                    name: this.state.name,
                    body : this.state.message
                })
            }
            else{
                this.setState({
                    errorMessage : errorMessage,
                    displayError : 'display-block'
                })
            } 
        }               
    }    

    sendEmail = (variables) => {
        fetch('https://api.jonathandarwin.com/email', {
            method : 'POST',
            headers : headers,
            body : JSON.stringify(variables)
          })
          .then(data => {
                this.setState({
                    buttonText : 'Send'
                })
                console.log('Your email has been sent!')
                alert(`Your email has been sent!`)
          })
          .catch(error => {
                this.setState({
                    buttonText : 'Send'
                })
                console.log({
                    message: `Error while sending email`,
                    data: error
                })
                alert('Error. Please try again.')
          })
        
        // window.emailjs.send(
        //     'gmail',
        //     'template_EVHBaYfP',
        //     variables
        // ).then(res => {
        //     this.setState({
        //         buttonText : 'Send'
        //     })
        //     alert('Your email has been sent!')            
        // }).catch(err => {
        //     this.setState({
        //         buttonText : 'Send'
        //     })
        //     console.log({
        //         message: `Error while sending email`,
        //         error : err
        //     })
        //     alert('Error. Please try again.')                        
        // })
    }
}

 
export default Contact;