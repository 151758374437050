import React from 'react';

const SkillItem = ({skill}) => {
    return (  
        <React.Fragment>
        <div class="grid">
            <div class="col-12">
                <h3>{skill.techName}</h3>
                <ul>
                    {generateSkillItem(skill.techList)}
                </ul>
            </div>
        </div>    
        <hr />
        </React.Fragment>        
    );
}

const generateSkillItem = (techList) => {
    return techList.map(tech => {
        return <li>{tech}</li>
    })
}
 
export default SkillItem;