import React from 'react';

const Profile = ({location, description}) => {
    return ( 
        <section id="section-profile">
            <div className="profile section-white">
                <h1><i className="fa fa-user m-right-10"></i>Profile</h1>                
                <div className="grid m-top-50">
                    <img id="img-profile" className="col-3 img-rounded" src={require('../../assets/profile2.jpg')}/>                                    
                    <div className="col-1"></div>
                    <div className="profile-information col-8">
                        <h1 className="name">Jonathan Darwin</h1>                  
                        <br />
                        <hr />                                                                  
                        <br />
                        <table id="table-profile">
                            <tr>
                                <td className="table-label">Location</td>
                                <td className="table-value">{location}</td>
                            </tr>
                            <tr>
                                <td className="table-label">University</td>
                                <td className="table-value">Bina Nusantara University</td>
                            </tr>
                            <tr>
                                <td className="table-label">Major</td>
                                <td className="table-value">Computer Science</td>
                            </tr>
                            <tr>
                                <td className="table-label">Minor</td>
                                <td className="table-value">Artificial Intelligence</td>
                            </tr>
                        </table>
                        <hr />
                        <div className="profile-description">{description}</div>        
                    </div>   
                </div>                     
            </div>
        </section>  
    );
}
 
export default Profile;