import React from 'react';

const WorkingExperienceItem = ({company}) => {        
    return (          
        <React.Fragment>
            <div class="grid m-bottom-20">
                <div class="col-1">
                    <img id="img-binus" class={getRounded(company.isRounded)} src={company.image}/>
                </div>
                <div class="col-9 m-left-20">
                    <h2 class="no-margin">{company.companyName}</h2>                    
                    <div class="text-brown italic m-top-10">{`${getDisplayDate(company.startDate)} - ${getDisplayDate(company.endDate)} (${getDateRange(company.startDate, company.endDate)})`}</div>
                    <hr class="m-top-20 m-bottom-20"/>
    
                    {generatePositionItem(company.position)}
                    
                </div>
            </div>
        </React.Fragment>
    );
}

const getRounded = (isRounded) => {
    return isRounded ? "img-rounded" : "";
}

const getDisplayDate = (raw) => {
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Des"]
    if(raw === "") return "Present"
    let date = new Date(raw)
    return `${monthList[date.getMonth()]} ${date.getFullYear()}`    
}

const getDateRange = (start, end) => {
    let startDate = new Date(start)
    let endDate = new Date()
    if(end !== "") endDate = new Date(end)

    let divYear = endDate.getFullYear() - startDate.getFullYear()
    let total = divYear * 12;
    let divMonth = endDate.getMonth() - startDate.getMonth()
    total += divMonth + 1;

    let year = parseInt(total / 12);
    let month = total % 12;

    if(year === 0){
        return `${month} months`
    }    
    else if(month === 0){
        return `${year} years`
    }
    return `${year} years ${month} months`
}

const generatePositionItem = (positionList) => {    
    return positionList.map(position => {
        return (
            <div class="grid m-bottom-20">
                <div class="col-12">
                    <h3 class="no-margin">{position.positionName}</h3>
                    <div class="italic m-top-10 text-brown">{`${getDisplayDate(position.startDate)} - ${getDisplayDate(position.endDate)} (${getDateRange(position.startDate, position.endDate)})`}</div>
                    <div class="m-top-20">
                        <ul>
                            {generatePositionDescription(position.description)}
                        </ul>                                                        
                    </div>
                </div>
            </div>      
        );
    })
}

const generatePositionDescription = (descriptionList) => {
    return descriptionList.map(description => {
        return (
            <li>{description}</li>   
        )
    })
}
 
export default WorkingExperienceItem;