let googleSheetApiKey = process.env.REACT_APP_GOOGLE_SHEET_API_KEY
let spreadSheetId = process.env.REACT_APP_SPREAD_SHEET_ID
let baseUrl = "https://sheets.googleapis.com/v4/spreadsheets"


export async function getSheetData(sheetName) {
    let response = await fetch(`${baseUrl}/${spreadSheetId}/values/${sheetName}?key=${googleSheetApiKey}`)
    let data = await response.json()
    return data
}

/** TODO: not working yet */
export async function appendLog() {
    let request = {
        "values" : [
            "test1",
            "test2",
            "test3"
        ]
    }
    return await fetch(
        `${baseUrl}/${spreadSheetId}/values/Visit Log:append?valueInputOption=USER_ENTERED&key=${googleSheetApiKey}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }
    )
}